(function () {
  window.messageHandler = function onMessage(event) {
      if (!event.data || event.data.action !== "initiateHandshake") return;

      window.handshakeIdData = event.data;
      window.removeEventListener("message", window.messageHandler);

      if (!window.sdkVersion) {
          sessionStorage.setItem("handshakeId", event.data.payload);
          window.parent.postMessage({result: true, messageId: event.data.messageId, payload: event.data.payload}, "*");
      }
  }

  window.locationHash = location.hash;

  if (parent === window) return;

  if (location.hash) {
      try {
          var options = JSON.parse(atob(location.hash.replace("#", "")));
          window.sdkVersion = options.version;
      } catch (e) {}
  }

  window.addEventListener("message", window.messageHandler);
})();
